<template>
  <div>
    <div class="row">
      <div class="col-12 q-pa-md">
        <q-btn-group outline class="float-right">
          <c-btn v-show="editable&&!disabled" label="회의추가" icon="add" @btnClicked="addCard" />
        </q-btn-group>
        <div class="row">
          <q-list bordered class="rounded-borders"
            v-for="(item, idx) in actionData.meetList"
            :key="idx">
            <q-form :ref="'editForm_' + idx">
                <!-- :caption="item.meetingContent"  -->
              <div :class="item.expanded ? 'col-12 q-pa-md' : 'col-4 q-pa-md'">
                <q-expansion-item 
                  :key="item.riskAssessmentMeetingId"
                  expand-separator
                  style="border-radius: 30px"
                  :icon="item.expanded ? 'groups' : 'person'"
                  :label="item.meetingTitle ? item.meetingTitle : '작성중'"
                  v-model="item.expanded"
                  :header-class="item.expanded ? 'bg-teal-5 text-white' : 'bg-teal-2'"
                  expand-icon-class="text-white"
                  v-on:click="listClick(item, idx)"
                  >
                  <q-separator />
                  <br>
                  <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                      <c-card title="회의정보 및 회의록" class="cardClassDetailForm">
                        <template slot="card-button">
                          <c-btn 
                            v-if="editable&&!disabled"
                            label="일괄저장" 
                            icon="save"
                            @btnClicked="saveCard(item, idx)" />
                          <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" @btnClicked="removeCard(item, idx)" />
                        </template>
                        <template slot="card-detail">
                          <!-- <div class="col-xs-12 col-sm-3 col-md-3">
                            <c-text
                              :required="true" 
                              :editable="editable"
                              :disabled="disabled"
                              label="회의명"
                              name="meetingName"
                              v-model="item.meetingName">
                            </c-text>
                          </div> -->
                          <div class="col-xs-12 col-sm-4 col-md-4">
                            <c-datepicker
                              :required="true" 
                              :editable="editable"
                              :disabled="disabled"
                              label="회의일"
                              name="period"
                              v-model="item.meetingDate"
                            />
                          </div>
                          <div class="col-xs-12 col-sm-4 col-md-4">
                            <c-datepicker
                              :editable="editable"
                              :disabled="disabled"
                              :range="true"
                              type="time"
                              :minuteStep="10"
                              label="회의시간"
                              name="period"
                              v-model="item.meetingTime"
                            />
                          </div>
                          <div class="col-xs-12 col-sm-4 col-md-4">
                            <c-text
                              :editable="editable"
                              :disabled="disabled"
                              type="url"
                              :afterIcon="[
                                {
                                  name: 'send',
                                  click: true,
                                  callbackName: 'link',
                                  color: '',
                                },
                              ]"
                              label="동영상 링크(URL 입력)"
                              name="youtubeLink"
                              v-model="item.youtubeLink"
                              @link="openWindow(item.youtubeLink)">
                            </c-text>
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md-6">
                            <c-textarea
                              :required="true" 
                              :editable="editable"
                              :disabled="disabled"
                              :rows="2"
                              :autogrow="false"
                              label="회의개요"
                              name="meetingContent"
                              v-model="item.meetingContent">
                            </c-textarea>
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md-6">
                            <c-textarea
                              :editable="editable"
                              :disabled="disabled"
                              :rows="2"
                              :autogrow="false"
                              label="특이사항"
                              name="meetingUnique"
                              v-model="item.meetingUnique">
                            </c-textarea>
                          </div>
                          <div class="col-6" style="padding-top:10px !important">
                            <c-upload 
                              :ref="'file' + idx"
                              :attachInfo="attachInfo"
                              label="회의록"
                              :editable="editable&&!disabled">
                            </c-upload>
                          </div>
                          <div class="col-6" style="padding-top:10px !important">
                            <c-upload
                              :ref="'file2' + idx"
                              :attachInfo="attachInfo2"
                              label="관련사진"
                              :editable="editable&&!disabled">
                            </c-upload>
                          </div>
                        </template>
                      </c-card>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <c-table
                        :ref="'table_' + idx" 
                        title="참석자 목록"
                        :columns="grid.columns"
                        :gridHeight="grid.height"
                        :data="item.attendList"
                        :filtering="false"
                        :columnSetting="false"
                        :usePaging="false"
                        :editable="editable&&!disabled"
                        selection="multiple"
                        rowKey="riskAssessAttendId"
                      >
                        <!-- 버튼 영역 -->
                        <template slot="table-button">
                          <q-btn-group outline >
                            <c-btn v-if="editable&&!disabled" :showLoading="false" label="직접추가" icon="add" @btnClicked="addAttend2(idx)" />
                            <c-btn v-if="editable&&!disabled" :showLoading="false" label="선택" icon="add" @btnClicked="addAttend(idx)" />
                            <c-btn 
                              v-if="editable&&!disabled" 
                              label="제외" 
                              icon="remove" 
                              @btnClicked="removeAttend(item, idx)" />
                          </q-btn-group>
                        </template>
                      </c-table>
                    </div>
                  </div>  
                </q-expansion-item>
              </div>
            </q-form>
          </q-list>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'risk-assess-meeting-detail',
  props: {
    actionData: {
      type: Object,
      default: () => ({
        meetList: [], // 회의 목록
      }),
    },
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
    assessPlan: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: null,
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        remark: '',
        reviewUserId: ''
      }),
    },
    targetData: {
      type: Object,
      default: () => ({
        riskAssessmentVendorId: '',
        riskAssessmentPlanId: '',
        assessVendorId: '',
        assessVendorName: '',
        processAssessStepCd: '',
        processAssessStepName: '',
      }),
    },
    planUpdateBtnData: {
      title: '계획',
      flag: false,
      research: '',
      planResearch: '',
    },
    updateBtnData: {
      title: '평가',
      flag: false,
      research: '',
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'belong',
            field: 'belong',
            label: '소속',
            align: 'left',
            type: 'text',
            style: 'width:30%',
            sortable: false,
          },
          {
            required: true,
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'left',
            type: 'text',
            style: 'width:30%',
            sortable: false,
          },{
            name: 'roleName',
            field: 'roleName',
            label: '직책',
            align: 'left',
            type: 'text',
            style: 'width:40%',
            sortable: false,
          },
        ],
        height: '425px'
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_ASSESS_MEETING',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'RISK_ASSESS_MEETING2',
        taskKey: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      isSave: false,
      detailUrl: '',
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveUrl: 'transactionConfig.saas.meeting.insert.url',
      item: null,
      completeUrl: '',
      isComplete: false,
      idx: 0,
      taskkey: '',
      taskkey2: '',
    };
  },
  computed: {
    disabled() {
      return this.param.stepCd === 'RAS000010' || this.targetData.processAssessStepCd === 'RS00000005'
            && !this.updateBtnData.flag
    },
    editable() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return this.$route.meta.editable && 
          (this.$store.getters.user.deptCd === this.targetData.assessVendorId
           ||this.assessPlan.approvalUserId === this.$store.getters.user.userId
           || this.assessPlan.reviewUserId === this.$store.getters.user.userId
           || this.assessPlan.regUserId === this.$store.getters.user.userId)
      } else {
        return this.$route.meta.editable && 
          (this.$store.getters.user.deptCd === this.targetData.assessVendorId
           ||this.assessPlan.approvalUserId === this.$store.getters.user.userId
           || this.assessPlan.reviewUserId === this.$store.getters.user.userId
           || this.assessPlan.regUserId === this.$store.getters.user.userId)
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.detailUrl = selectConfig.saas.meeting.get.url;
      this.insertUrl = transactionConfig.saas.meeting.update.url;
      this.updateUrl = transactionConfig.saas.meeting.update.url;
      this.deleteUrl = transactionConfig.saas.meeting.delete.url;
      
      this.taskkey = this.$_.cloneDeep(this.attachInfo.taskkey)
      this.taskkey2 = this.$_.cloneDeep(this.attachInfo2.taskkey)

    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.item.riskAssessmentMeetingId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.item, 'meetingTitle', _result.data.meetingTitle)
        this.$set(this.item, 'meetingName', _result.data.meetingName)
        this.$set(this.item, 'meetingDate', _result.data.meetingDate)
        this.$set(this.item, 'meetingTime',  [_result.data.meetingStartTime, _result.data.meetingEndTime])
        this.$set(this.item, 'attendList', _result.data.attendList)
        this.$set(this.item, 'meetingContent', _result.data.meetingContent)
        this.$set(this.item, 'meetingUnique', _result.data.meetingUnique)
        this.$set(this.item, 'youtubeLink', _result.data.youtubeLink)
        this.$set(this.item, 'newCardKey', null)
        this.$set(this.item, 'expanded', true)
        

        this.$set(this.$refs['file'+ this.idx][0].attachInfo, 'taskKey', this.item.riskAssessmentMeetingId);
        this.$set(this.$refs['file2'+ this.idx][0].attachInfo, 'taskKey',  this.item.riskAssessmentMeetingId);
      },);
    },
    addCard() {
      this.actionData.meetList.push({
        riskAssessmentMeetingId: null,
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        assessVendorId: this.targetData.assessVendorId,
        assessVendorName: this.targetData.assessVendorName,
        meetingName: '',
        meetingDate: '',
        meetingStartTime: '',
        meetingEndTime: '',
        meetingContent: '',
        meetingUnique: '',
        youtubeLink: '',
        meetingTime: [],
        attendList: [],
        newCardKey: uid(),
        expanded: true,
      })
      let count = 0;
      this.$_.forEach(this.actionData.meetList, _item => {
        if ((this.actionData.meetList.length-1) === count) {
          count ++;
          return;
        }
        this.$set(_item, 'expanded', false);
        count ++;
      })
      this.setAttachInfo();
    },
    setAttachInfo() {
      let idx = this.actionData.meetList.length-1;
      setTimeout(() => {
        this.$set(this.$refs['file'+ idx][0].attachInfo, 'taskClassCd', 'RISK_ASSESS_MEETING');
        this.$set(this.$refs['file'+ idx][0].attachInfo, 'taskKey', '');
        this.$set(this.$refs['file2'+ idx][0].attachInfo, 'taskClassCd', 'RISK_ASSESS_MEETING2');
        this.$set(this.$refs['file2'+ idx][0].attachInfo, 'taskKey',  '');
      }, 300);
    },
    removeCard(item) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (item.newCardKey) {
            this.actionData.meetList = this.$_.reject(this.actionData.meetList, {newCardKey :item.newCardKey});
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          } else {
            this.$http.url = this.$format(this.deleteUrl, item.riskAssessmentMeetingId);
            this.$http.type = 'DELETE';
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.actionData.meetList = this.$_.reject(this.actionData.meetList, {riskAssessmentMeetingId :item.riskAssessmentMeetingId});
           },);
          }
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addAttend(idx) {
      this.idx = idx
      this.popupOptions.title = "회의참석자 검색"; 
      this.popupOptions.param = {
        type: 'multiple',
        isAllVendor: true,
        vendorCd: this.targetData.assessVendorId,
        plantCd: this.assessPlan.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeUserPopup;
      // this.actionData.meetList[idx].attendList.splice(0, 0, {
      //   riskAssessAttendId: uid(),
      //   riskAssessmentPlanId: this.param.riskAssessmentPlanId,
      //   belong: '',
      //   userName: '',
      //   roleName: '',
      //   editFlag: 'C', 
      //   regUserId: this.$store.getters.user.userId,
      // })
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.actionData.meetList[this.idx].attendList, {
            userId: _item.userId,
          });
          if (index === -1) {
            this.actionData.meetList[this.idx].attendList.splice(0, 0, {
              riskAssessAttendId: uid(),
              riskAssessmentPlanId: this.param.riskAssessmentPlanId,
              belong: _item.deptName,
              userId: _item.userId,
              userName: _item.userName,
              roleName: _item.jobName,
              editFlag: 'C', 
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    addAttend2(idx) {
      this.idx = idx
      this.actionData.meetList[this.idx].attendList.splice(0, 0, {
        riskAssessAttendId: uid(),
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        belong: '',
        userId: '',
        userName: '',
        roleName: '',
        editFlag: 'C', 
        regUserId: this.$store.getters.user.userId,
      })
    },
    removeAttend(item, idx) {
      let selectData = this.$refs['table_' + idx][0].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!item.deleteAttendList) item.deleteAttendList = [];
        this.$_.forEach(selectData, _item => {
          if (this.$_.findIndex(this.actionData.meetList[idx].deleteAttendList, { riskAssessAttendId: _item.riskAssessAttendId }) === -1
            && item.editFlag !== 'C') {
            item.deleteAttendList.push(_item);
          }
          item.attendList = this.$_.reject(item.attendList, _item);
          this.$refs['table_'+ idx][0].$refs['compo-table'].clearSelection();
        });
      }
    },
    saveCard(item, idx) {
      if (item.riskAssessmentMeetingId) {
        this.saveUrl = transactionConfig.saas.meeting.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.saas.meeting.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm_' + idx][0].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, item.attendList)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.item = item;
                this.idx = idx;
                // item.meetingName = '[' + item.meetingDate + '] ' + item.meetingContent;
                item.regUserId = this.$store.getters.user.userId
                item.chgUserId = this.$store.getters.user.userId
                item.meetingStartTime = item.meetingTime[0]
                item.meetingEndTime = item.meetingTime[1]
                item.fileList1 = this.$refs['file'+ idx][0].fileList
                item.fileList2 = this.$refs['file2'+ idx][0].fileList
                this.$http.url = this.saveUrl;
                this.$http.type = this.mappingType;
                this.$http.param = item
                this.$http.request((_result) => {
                  this.saveCallback(_result)
                },);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      this.item.riskAssessmentMeetingId = _result.data;
      window.getApp.$emit('APP_REQUEST_SUCCESS');

      this.getDetail();
    },
    listClick(item, idx) {
      this.item = item;
      this.idx = idx;
      if (this.item.riskAssessmentMeetingId) {
        this.$set(this.$refs['file'+ idx][0].attachInfo, 'taskKey', this.item.riskAssessmentMeetingId)
        this.$set(this.$refs['file2'+ idx][0].attachInfo, 'taskKey', this.item.riskAssessmentMeetingId)
      } else {
        this.$set(this.$refs['file'+ idx][0].attachInfo, 'taskKey', '')
        this.$set(this.$refs['file2'+ idx][0].attachInfo, 'taskKey', '')
        this.$set(this.$refs['file'+ idx][0].attachInfo, 'taskClassCd', 'RISK_ASSESS_MEETING')
        this.$set(this.$refs['file2'+ idx][0].attachInfo, 'taskClassCd', 'RISK_ASSESS_MEETING2')
      }
      this.$_.forEach(this.$_.reject(this.actionData.meetList, item), _item => {
        _item.expanded = false;
      })

      this.actionData.meetList = this.$_.reject(this.actionData.meetList, item)
      this.actionData.meetList.splice(this.actionData.meetList.length,0, item)

    },
    openWindow(data) {
      window.open(data, '','');
    },
  }
};
</script>
 