var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 q-pa-md" },
          [
            _c(
              "q-btn-group",
              { staticClass: "float-right", attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable&&!disabled",
                    },
                  ],
                  attrs: { label: "회의추가", icon: "add" },
                  on: { btnClicked: _vm.addCard },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.actionData.meetList, function (item, idx) {
                return _c(
                  "q-list",
                  {
                    key: idx,
                    staticClass: "rounded-borders",
                    attrs: { bordered: "" },
                  },
                  [
                    _c("q-form", { ref: "editForm_" + idx, refInFor: true }, [
                      _c(
                        "div",
                        {
                          class: item.expanded
                            ? "col-12 q-pa-md"
                            : "col-4 q-pa-md",
                        },
                        [
                          _c(
                            "q-expansion-item",
                            {
                              key: item.riskAssessmentMeetingId,
                              staticStyle: { "border-radius": "30px" },
                              attrs: {
                                "expand-separator": "",
                                icon: item.expanded ? "groups" : "person",
                                label: item.meetingTitle
                                  ? item.meetingTitle
                                  : "작성중",
                                "header-class": item.expanded
                                  ? "bg-teal-5 text-white"
                                  : "bg-teal-2",
                                "expand-icon-class": "text-white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.listClick(item, idx)
                                },
                              },
                              model: {
                                value: item.expanded,
                                callback: function ($$v) {
                                  _vm.$set(item, "expanded", $$v)
                                },
                                expression: "item.expanded",
                              },
                            },
                            [
                              _c("q-separator"),
                              _c("br"),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8",
                                  },
                                  [
                                    _c(
                                      "c-card",
                                      {
                                        staticClass: "cardClassDetailForm",
                                        attrs: { title: "회의정보 및 회의록" },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "card-button" },
                                          [
                                            _vm.editable && !_vm.disabled
                                              ? _c("c-btn", {
                                                  attrs: {
                                                    label: "일괄저장",
                                                    icon: "save",
                                                  },
                                                  on: {
                                                    btnClicked: function (
                                                      $event
                                                    ) {
                                                      return _vm.saveCard(
                                                        item,
                                                        idx
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.editable && !_vm.disabled
                                              ? _c("c-btn", {
                                                  attrs: {
                                                    label: "삭제",
                                                    icon: "remove",
                                                  },
                                                  on: {
                                                    btnClicked: function (
                                                      $event
                                                    ) {
                                                      return _vm.removeCard(
                                                        item,
                                                        idx
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "template",
                                          { slot: "card-detail" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xs-12 col-sm-4 col-md-4",
                                              },
                                              [
                                                _c("c-datepicker", {
                                                  attrs: {
                                                    required: true,
                                                    editable: _vm.editable,
                                                    disabled: _vm.disabled,
                                                    label: "회의일",
                                                    name: "period",
                                                  },
                                                  model: {
                                                    value: item.meetingDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "meetingDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.meetingDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xs-12 col-sm-4 col-md-4",
                                              },
                                              [
                                                _c("c-datepicker", {
                                                  attrs: {
                                                    editable: _vm.editable,
                                                    disabled: _vm.disabled,
                                                    range: true,
                                                    type: "time",
                                                    minuteStep: 10,
                                                    label: "회의시간",
                                                    name: "period",
                                                  },
                                                  model: {
                                                    value: item.meetingTime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "meetingTime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.meetingTime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xs-12 col-sm-4 col-md-4",
                                              },
                                              [
                                                _c("c-text", {
                                                  attrs: {
                                                    editable: _vm.editable,
                                                    disabled: _vm.disabled,
                                                    type: "url",
                                                    afterIcon: [
                                                      {
                                                        name: "send",
                                                        click: true,
                                                        callbackName: "link",
                                                        color: "",
                                                      },
                                                    ],
                                                    label:
                                                      "동영상 링크(URL 입력)",
                                                    name: "youtubeLink",
                                                  },
                                                  on: {
                                                    link: function ($event) {
                                                      return _vm.openWindow(
                                                        item.youtubeLink
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.youtubeLink,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "youtubeLink",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.youtubeLink",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xs-12 col-sm-6 col-md-6",
                                              },
                                              [
                                                _c("c-textarea", {
                                                  attrs: {
                                                    required: true,
                                                    editable: _vm.editable,
                                                    disabled: _vm.disabled,
                                                    rows: 2,
                                                    autogrow: false,
                                                    label: "회의개요",
                                                    name: "meetingContent",
                                                  },
                                                  model: {
                                                    value: item.meetingContent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "meetingContent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.meetingContent",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xs-12 col-sm-6 col-md-6",
                                              },
                                              [
                                                _c("c-textarea", {
                                                  attrs: {
                                                    editable: _vm.editable,
                                                    disabled: _vm.disabled,
                                                    rows: 2,
                                                    autogrow: false,
                                                    label: "특이사항",
                                                    name: "meetingUnique",
                                                  },
                                                  model: {
                                                    value: item.meetingUnique,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "meetingUnique",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.meetingUnique",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-6",
                                                staticStyle: {
                                                  "padding-top":
                                                    "10px !important",
                                                },
                                              },
                                              [
                                                _c("c-upload", {
                                                  ref: "file" + idx,
                                                  refInFor: true,
                                                  attrs: {
                                                    attachInfo: _vm.attachInfo,
                                                    label: "회의록",
                                                    editable:
                                                      _vm.editable &&
                                                      !_vm.disabled,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-6",
                                                staticStyle: {
                                                  "padding-top":
                                                    "10px !important",
                                                },
                                              },
                                              [
                                                _c("c-upload", {
                                                  ref: "file2" + idx,
                                                  refInFor: true,
                                                  attrs: {
                                                    attachInfo: _vm.attachInfo2,
                                                    label: "관련사진",
                                                    editable:
                                                      _vm.editable &&
                                                      !_vm.disabled,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4",
                                  },
                                  [
                                    _c(
                                      "c-table",
                                      {
                                        ref: "table_" + idx,
                                        refInFor: true,
                                        attrs: {
                                          title: "참석자 목록",
                                          columns: _vm.grid.columns,
                                          gridHeight: _vm.grid.height,
                                          data: item.attendList,
                                          filtering: false,
                                          columnSetting: false,
                                          usePaging: false,
                                          editable:
                                            _vm.editable && !_vm.disabled,
                                          selection: "multiple",
                                          rowKey: "riskAssessAttendId",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "table-button" },
                                          [
                                            _c(
                                              "q-btn-group",
                                              { attrs: { outline: "" } },
                                              [
                                                _vm.editable && !_vm.disabled
                                                  ? _c("c-btn", {
                                                      attrs: {
                                                        showLoading: false,
                                                        label: "직접추가",
                                                        icon: "add",
                                                      },
                                                      on: {
                                                        btnClicked: function (
                                                          $event
                                                        ) {
                                                          return _vm.addAttend2(
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.editable && !_vm.disabled
                                                  ? _c("c-btn", {
                                                      attrs: {
                                                        showLoading: false,
                                                        label: "선택",
                                                        icon: "add",
                                                      },
                                                      on: {
                                                        btnClicked: function (
                                                          $event
                                                        ) {
                                                          return _vm.addAttend(
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.editable && !_vm.disabled
                                                  ? _c("c-btn", {
                                                      attrs: {
                                                        label: "제외",
                                                        icon: "remove",
                                                      },
                                                      on: {
                                                        btnClicked: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeAttend(
                                                            item,
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }